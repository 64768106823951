:root {
    /* General Styles */
    --background: #fff;
    --body-text: #000;
    --exit-modal-color: #dfe3e9;
    --error-color: #ff8a80;
    --header-text: #ffffff;
    --app-header-text: #000;
    --link-text: #82b1ff;
    --token-balance-text: #000;
    --warning: #ffc780;
    --address-color: #fc02a7;

    /* Input Fields */
    --input-text: #000;
    --input-placeholder-text: #000;
    --input-hover-background: #31313b;
    --input-hover-placeholder-text: #818187;
    --input-hover-border: #4c5480;

    /* Button Styles */
    --button-text: #fff;
    --button-background: linear-gradient(-60deg, #cb1a8f, #fc02a7);
    --button-border: #cb1a8f;
    --inactive-button-border: rgba(140, 158, 255, 0.2);
    --inactive-button-text: #000;

    /* Panel Styles */
    --panel-border: rgb(111, 81, 253);
    --panel-background: #f4f4f4;
    --panel-header-background: #f4f4f4;
    --panel-row-text: #000;

    /* Selector Styles */
    --slider-main: #8c9eff;
    --highlighted-selector-background: #fc02a7;
    --highlighted-selector-border: #fc02a7;
    --highlighted-selector-text: #000;
    --selector-background: #2e2f39;
}

body {
    background-color: var(--background);
    overflow-y: hidden;
}

.app-shell {
    background-color: var(--background);
    height: calc(100vh - 78px);
    width: 100%;
    overflow-y: scroll;
}

.popup-content {
    color: var(--background);
    border-radius: 4px;
    height: 60px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
